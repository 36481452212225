import { ICustomColor } from "app/models/DataNode";

export const TRIAL_DAYS = 7;
export const PROFILE = 'profile';
export const ID_TOKEN = 'token';
export const CURRENT_ROLE = 'currentRole';
export const REDIRECT_PATH = 'lastNavigatedPath';
export const PATTERNS = {
  Number: /^\d+$/,
  Decimal: /^-?[0-9,.]+$/,
  DecimalWithHalf: /^\d+(\.5)?$/,
  DecimalWithMin1: /^\d+(\.\d{1})?$/,
  DecimalWithMax9999: /^\d{1,4}(?:\.\d{1,2})?$/,
  Phone: /^(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})$/,
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{1,}[^a-zA-Z\d\s:])(?=.{6,})/,
  Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  TimeSlot: /^(0[0-9]|1[012]):([0-5][0-9])[a|p]m$/
};
export const PAGINATE_SHOW_PAGES = 6;
export const QUERY_PARAMS_FOR_INVOICE_FILTER = ['dueAtStart', 'dueAtEnd'];
export const PIE_CHART_COLORS: Record<'low' | 'top', ICustomColor[]> = {
  'low' : [
    {
      value: '#1e1b4b',
    },
    {
      value: '#3730a3',
    },
    {
      value: '#5647d4',
    },
    {
      value: '#818cf8',
    },
    {
      value: '#a5b4fc',
    },
  ],
  'top': [
    {
      value: '#450a0a',
    },
    {
      value: '#991b1b',
    },
    {
      value: '#d82d42',
    },
    {
      value: '#f87171',
    },
    {
      value: '#fca5a5',
    },
  ]
};

export const US_STATES: Array<{name: string, abbreviation: string}> = [
  { "name": "Alabama", "abbreviation": "AL" },
  { "name": "Alaska", "abbreviation": "AK" },
  { "name": "American Samoa", "abbreviation": "AS" },
  { "name": "Arizona", "abbreviation": "AZ" },
  { "name": "Arkansas", "abbreviation": "AR" },
  { "name": "California", "abbreviation": "CA" },
  { "name": "Colorado", "abbreviation": "CO" },
  { "name": "Connecticut", "abbreviation": "CT" },
  { "name": "Delaware", "abbreviation": "DE" },
  { "name": "District Of Columbia", "abbreviation": "DC" },
  { "name": "Federated States Of Micronesia", "abbreviation": "FM" },
  { "name": "Florida", "abbreviation": "FL" },
  { "name": "Georgia", "abbreviation": "GA" },
  { "name": "Guam", "abbreviation": "GU" },
  { "name": "Hawaii", "abbreviation": "HI" },
  { "name": "Idaho", "abbreviation": "ID" },
  { "name": "Illinois", "abbreviation": "IL" },
  { "name": "Indiana", "abbreviation": "IN" },
  { "name": "Iowa", "abbreviation": "IA" },
  { "name": "Kansas", "abbreviation": "KS" },
  { "name": "Kentucky", "abbreviation": "KY" },
  { "name": "Louisiana", "abbreviation": "LA" },
  { "name": "Maine", "abbreviation": "ME" },
  { "name": "Marshall Islands", "abbreviation": "MH" },
  { "name": "Maryland", "abbreviation": "MD" },
  { "name": "Massachusetts", "abbreviation": "MA" },
  { "name": "Michigan", "abbreviation": "MI" },
  { "name": "Minnesota", "abbreviation": "MN" },
  { "name": "Mississippi", "abbreviation": "MS" },
  { "name": "Missouri", "abbreviation": "MO" },
  { "name": "Montana", "abbreviation": "MT" },
  { "name": "Nebraska", "abbreviation": "NE" },
  { "name": "Nevada", "abbreviation": "NV" },
  { "name": "New Hampshire", "abbreviation": "NH" },
  { "name": "New Jersey", "abbreviation": "NJ" },
  { "name": "New Mexico", "abbreviation": "NM" },
  { "name": "New York", "abbreviation": "NY" },
  { "name": "North Carolina", "abbreviation": "NC" },
  { "name": "North Dakota", "abbreviation": "ND" },
  { "name": "Northern Mariana Islands", "abbreviation": "MP" },
  { "name": "Ohio", "abbreviation": "OH" },
  { "name": "Oklahoma", "abbreviation": "OK" },
  { "name": "Oregon", "abbreviation": "OR" },
  { "name": "Palau", "abbreviation": "PW" },
  { "name": "Pennsylvania", "abbreviation": "PA" },
  { "name": "Puerto Rico", "abbreviation": "PR" },
  { "name": "Rhode Island", "abbreviation": "RI" },
  { "name": "South Carolina", "abbreviation": "SC" },
  { "name": "South Dakota", "abbreviation": "SD" },
  { "name": "Tennessee", "abbreviation": "TN" },
  { "name": "Texas", "abbreviation": "TX" },
  { "name": "Utah", "abbreviation": "UT" },
  { "name": "Vermont", "abbreviation": "VT" },
  { "name": "Virgin Islands", "abbreviation": "VI" },
  { "name": "Virginia", "abbreviation": "VA" },
  { "name": "Washington", "abbreviation": "WA" },
  { "name": "West Virginia", "abbreviation": "WV" },
  { "name": "Wisconsin", "abbreviation": "WI" },
  { "name": "Wyoming", "abbreviation": "WY" }
];